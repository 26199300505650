import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Aries.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Aries Woman
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/aries"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Aries</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Aries Woman</h4>
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Aries Woman</h1>
                <h2 className="text-sm md:text-base ml-4">Mar 21 - Apr 19</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Aries Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Aries Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Aries Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-love");
                  }}>
                  <h5 className="font-semibold text-black">Aries Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-nature");
                  }}>
                  <h5 className="font-semibold text-black">Aries Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-man");
                  }}>
                  <h5 className="font-semibold text-black">Aries Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Aries Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-traits");
                  }}>
                  <h5 className="font-semibold text-black">Aries Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/aries/aries-facts");
                  }}>
                  <h5 className="font-semibold text-black">Aries Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
            <p className="font-bold text-orange-500 inline-block mr-2">
Aries Woman Traits:
              </p>career-oriented fiery, energetic, independent, outgoing, optimistic, intelligent, creative, bold, spontaneous, strong, competitive, unbiased, sentimental
<br/><br/>

<p className="font-bold text-orange-500 inline-block mr-2">Compatible Signs for Aries Women:</p>Gemini, Leo, Sagittarius, and Aquarius
<br/><br/>

Career-oriented
Mars-ruled females can handle almost any profession that a man can handle. You are a strong believer in naturally keen achievers in your profession. It would be tough for you to give up your job and career for someone. You have that confidence to balance both sides of life, either career or personal; you don't want to leave one thing for another, and you believe that you feel that you can do all the tasks better than men. You are a woman who is very devoted to their work. 

<br/><br/>
You always feel that you can do all the tasks better than men. You can outperform in occupations like modeling, make-up styling, hairdresser, fashion stylist, and acting. You always love to be in the spotlight. There is practically nothing that you can't handle.<br/><br/>
You can be very proud of the fact that you are the woman to give your career priority. You love to go to the beach, you love to travel, and you love to cook. You never get bored or frustrated with your work. You have great enthusiasm for your job. You always have a good feeling about your work. You are always ready to learn new things. This character trait makes you a very successful woman. 
<br/><br/>

<p className="playfair text-black text-xl md:text-3xl">
Fiery and energetic
                </p><br/>
You are charismatic, enthusiastic, and passionate. You are born with bombastic energy and zeal to take on the world. You are capable of getting things done by prompting tasks and tasks and aromatizing willingness in whatever you do. Your energy and spirit get fizzled out when you don't get attention and commendation. 
<br/><br/>
It also gets get waved out when expected results are not derived from it. When you give your best to do things and still fail to achieve that task, after that, you become angry and frustrated when you fail to perform up to your mark or someone criticizes you for your performance. At the same time, if you are rebellious, you tend to lose out on lucrative opportunities. As a person, you don't need anyone to motivate you. You should learn the art of channelizing your vibrancy in the right direction in order to get what you deserve.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">
 Independent and outgoing
                </p><br/>
Aries woman urges on have complete sovereignty throughout life. You are very outgoing and get along with individuals of the opposite gender quite easily. You don't need anyone's support whenever you feel yourself in trouble; you found yourself strong enough to deal with, to fight your problems and battles by yourself. This kind of gesture towards life makes you different from others. That's the reason you believe that you can handle any kind of situation on your own. You always want to be a leader and would like to be bossed around in professional life and social circle. You are trendy and self-reliant and are a motivation to other women. You won't be dependent emotionally and financially on others.

 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">
 Optimistic and intelligent
                </p><br/>
There is glowing positivity inside you, and you continuously look forward. You have a firm belief that your tomorrow will be better than today. You are knowledgeable and possess a brainy mind that helps you outshine others. You always bring a wholly unique and special outlook to whatever you handle. You have a different manner of seeing things and executing them in the way you want. Your world is in continuous motion, and you have plenty of brain to achieve goals. You are efficient in all enterprises of life. You always look onward to new challenges and novel circumstances in life. You want to crack down on these challenges and problems instead of postponing them for the following day.
.
<br/><br/>
<p className="playfair text-black text-xl md:text-3xl">
Bold, creative and spontaneous
                </p><br/>
You are a brave natural leader. You are innovative in all your ways. Everything you do, everything you want to do, is full of creativity and uniqueness. Spontaneity is imbued in your character. You are courageous and firm in your opinions. You absolutely hate it if things get delayed/postponed or are not in your command. 
<br/><br/>
Whenever new challenges/problems arise, you tackle them full steam and with spontaneity. You assume in enduring the challenges head-on and living life to the fullest. You are a bold and daring warrior and understand to fight in harsh and challenging times. You tend to become aggressive and offensive, which sometimes adversely impacts your relationships.
 
<br/><br/>

<p className="playfair text-black text-xl md:text-3xl">
Competitive and unbiased
                </p><br/>
You are mentally healthy and competitive but not discriminatory or one-sided. You want to succeed for yourself, honest and fair. You are ambitious and often take up more tasks than your ability to deal with them. You never mimic other people and win the challenges and competitions without hustling. You always help those who you feel are right. You would struggle through thicks and thins single-handedly to be one in all aspects of life.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">
 Competitive and unbiased
                </p><br/>
It is challenging for you to configure your sentiments and emotions. You feel vulnerable only when you lose hope and tolerance; otherwise, you are a warrior. You would never give up things in your personal and professional life and put constant effort into making things correct and functioning. It would be best to give ample time to review the matters that you have failed to control or are not going swiftly. You are positively sentimental and keep your emotions alive. You lose your opinions and interest after you feel disconnected. Your horoscope sign is a vital tool for comprehending and understanding your personality, relationships, and love compatibility on a more profound level. 
<br/><br/>
<p className="playfair text-black text-xl md:text-3xl">
Discover more about yourself with our best astrologers!
                </p>
<br/>
<p className="playfair text-black text-xl md:text-3xl">
How is Aries woman in love and relationships?
                </p><br/>
Aries women are in love, and their relationship is extremely loyal and supportive. You will feel like you are a gorgeous woman when falling in love. You are a little impatient and show your emotions and feelings for him in a flirting game. 
<br/><br/>
You are not shy when it comes to expressing your inner feelings and do everything to do for your loved ones. You will attract your partner with your energetic, optimistic and confident character. You are a strong personality, and if your partner is in trouble, you will play the role of savior to help or save him.  <br/><br/>
By doing so, you are making him dependent on you, and he cannot learn his lesson and find a way to come out of problems.

<br/><br/>
<p className="playfair text-black text-xl md:text-3xl">
How To Attract Aries Woman
                </p><br/>
Aries sign is governed by fire, and Mars and women born under this sign are high-spirited, bold, and passionate in every term. 
<br/><br/>
For those who have eyes on an Aries woman, it's high time to evaluate your emotions and express your love and strength to win her heart. Because an Aries woman wants intense, intimate, and romantic partners, who always put them on top of their priority list, so, don't worry about any terms; we are here with some ways to attract ambitious and self-dependent Aries women.
 <br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2"></p>Be strong and dependable: Aries women get attracted to a man who is strong and dependent on her. She is a woman who loves to rule ahead in the relationship. She dislikes weak men and focuses too much on the problems—a person who is not afraid of difficulties but tries harder to solve them. <br/><br/>
 She might be attracted to a romantic man. An insecure man may not tempt her. <br/><br/>
 She is ready to lose her interest as soon as the man starts to depend on her too much. In short, an Aries woman wants a strong personality.


<br/><br/>
 
<p className="font-bold text-orange-500 inline-block mr-2">Be an attention giver:</p>Aries women love to get attention from their partners. It would be best if you gave them additional surprises and attention-seeking techniques that should meet her preferences and taste.<br/><br/>
You should also make her feel special and loved. This is the best way to get her attention. After all, it's not the role of women to make their partners happy. It's the job of the man to make the woman happy. 

<br/><br/>
 
<p className="font-bold text-orange-500 inline-block mr-2">Be sincere and loyal:</p>Aries women are always inclined towards reliable partners. Sincerity and loyalty attract every woman, and this is the only thing she admired the most in her relationship because when it's no loyalty, there is no love in between. She expects sincerity, truth, and commitment from a partner. No matter how harsh the reality is, try to confess in front of her, but if you try to hide something, remember that the slightest lie will make her feel detached and put her away from the relationship forever.
<br/><br/>
 
<p className="font-bold text-orange-500 inline-block mr-2">Be passionate:</p>Aries woman likes a man who is stunningly straightforward in approach. They love inspirational men who love to enjoy every moment, appreciate life, and do things that show that they enjoy life to the fullest with them. It would be best if you kept them happy by satisfying their innermost desires.
 <br/><br/>
Know your unique strengths and weaknesses with the help of our expert astrologers!

<br/><br/>
     
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
